import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import './Navbar.css';
import { IconContext } from 'react-icons';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as IoIcons1 from 'react-icons/io5';
import Prof from "../Assets/goal1.png";
import * as  IconName  from "react-icons/gr";
import * as  IconName2  from "react-icons/md";
import {connect,useDispatch} from 'react-redux';
import {logout} from "../../actions/userActions";



function Navbar(props) {
  const [sidebar, setSidebar] = useState(false);
  const dispatch = useDispatch();
  const logoutHandler = async () => {

    await dispatch(logout());


  };
  const showSidebar = () => setSidebar(!sidebar);

  
  return (
    <>
    
{props.data===undefined || props.data['success'] == false || props.data['isExist'] == false ?
<p></p>
:
<> 

      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbarrr'>
          <Link to='#' className='menu-barsss'>
            <FaIcons.FaBars onClick={showSidebar} style={{color:"white"}} />
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menuuu activeee' : 'nav-menuuu'}>

      
          <ul className='nav-menu-itemsss' onClick={showSidebar}>
          <li className='navbar-toggleee'>
              <Link to='#' className='menu-barsss'>
                <AiIcons.AiOutlineClose id="displayx" style={{color:"white"}} />
              </Link>
            </li>

          <div style={{width:"100%",height:"fit-content",textAlign:"center"}}>
           <img src={props.data.user.images[0].url} style={{width:"150px",height:"150px",marginTop:"5%",borderRadius:"60%"}}></img>
      <p style={{marginTop:"5%",color:"white"}}>{props.data.user.firstName} {props.data.user.lastName}</p>
      <p style={{color:"white"}}>{props.data.user.email}</p>
      
       </div>

          
          
                {/* <li   className="nav-texttt" style={{backgroundColor:props.color1,marginTop:"10%"}} >
                  <Link to='Dashboard'>
                  <IconName2.MdOutlineDashboard style={{color:"black"}}  />
                    <span className="span">Dashboard</span>
                  </Link>
                </li> */}


                <li  className="nav-texttt" style={{marginBottom:"3px"}} >
                  <Link to='/'>
                  <i class="fa fa-home" id="whitecolor"></i>
                    <span className="span" id="whitecolor">Home</span>
                  </Link>
                </li>
                
            
                

            {/*    <li  className="nav-texttt" style={{backgroundColor:props.color3}}>
                  <Link to='Faq'>
                  <IoIcons.IoMdHelpCircle style={{color:"black"}} />
                    <span className="span">FAQ</span>
                  </Link>
  </li> */}
                <li id="logoutt" className="nav-texttt" style={{backgroundColor:'#939DA6',marginTop:"0px"}}>
                <Link to='Profile'>
                <i class="fa fa-user-circle" id="whitecolor" style={{color:"#ad1f23"}}></i>
                    <span id="whitecolor" className="span" style={{color:"#ad1f23"}}>Profile</span>
                </Link>
                </li>
                <li onClick={logoutHandler} id="logoutt" className="nav-texttt" style={{position:"absolute",bottom:"0"}}>
                <Link to='/'>
                <i class="fa fa-sign-out" id="whitecolor"></i>
                    <span id="whitecolor" className="span">Logout</span>
                </Link>
                </li>
             
          </ul>
        </nav>

        
      </IconContext.Provider>
      </>}
    </>
  );
}

function mapStateToProps(state) {
  return {data:state.auth.authData}
}
export default connect (mapStateToProps) (Navbar);
