import React , {Component}  from 'react';
import './Getstarted.scss';

import Phonecarousel from './Slider';
import JoinusDialog from "../Dialogs/JoinusDialog.js";
class Getstarted extends Component{
  constructor(props) {
    super(props);
    this.loadingScreenRef = React.createRef(null);
    this.state = {animate: false};
  }

  componentDidMount(){
   
   
  
  }
  
       render (){
           return (
<>

<div id="GetStarted" >
    
    
<div id="startbackimage"  >
  
     
    
        </div>
    
        <div className="divvvstart" data-aos="fade-right" data-aos-once="true"  >
           <p className="titlestart" style={{color:"#ad1f23"}}> LET'S CONNECT!   </p>
           <br></br>
           <JoinusDialog idname="getstartedbutton"   name="DOWNLOAD APP" class="mobileview" ></JoinusDialog>
           <br></br>
        <p className="fontsize" style={{color:"white"}}>Want to meet People with the same athletic interest?<br></br>
        Download
          speer app and join the huge athletes
          <br></br> community, Connect, Chat, and share your love to sports with us.
           
           </p>
           <br></br>
         
             
             
</div>

<div id="carrstyle">

<Phonecarousel style={{width:"100%",height:"100%"}} data-aos="fade-left" data-aos-once="true"></Phonecarousel>

</div>







<div id="bottom">
  <p>Join the athletes that are already part of our community.</p>
 
</div>
  



</div>
</>


           )
       }


}

export default Getstarted;