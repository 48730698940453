

import React, { Component } from "react";
import './Profile.css';
import Imageuser from '../Assets/goal1.png';
import { borderRadius } from "@material-ui/system";
import ImageDialog from "../Dialogs/Imageuser";
import {connect} from 'react-redux';
import { getUserMatchings } from '../../actions/userActions';

function mapStateToProps(state) {
  return {data:state.auth.authData}
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages:0,
      count:0
    };
  }

  render() {
    
    const match = async () => {
      if(this.state.count==0){
        this.state.count++;
    const matchings = await getUserMatchings(this.props.data.user._id);
        if (matchings.message!="no matchings!") {
          this.setState({messages:matchings.matchings.length});
        };
      };
      };
    match();

    let rows = [];

    for (let i = 1; i < this.props.data.user.images.length; i++) {

      rows.push(  <ImageDialog imagename={this.props.data.user.images[i].url}></ImageDialog>);

    }
    return (
<>

{this.props.data===undefined || this.props.data['success'] == false || this.props.data['isExist'] == false ? 
<p>You are not Logged in ! </p> :


<div className='profilediv'>
      <h1 style={{color:'#ad1f23'}}>Profile</h1>
      <hr className="hr"></hr>

<div className="infocontainer">
<div className="containerinfo2">

<div id="newdiv">
<img src={this.props.data.user.images[0].url} className="imageuser" ></img>
<div className="information">
 <p style={{color:"#072438"}}> {this.props.data.user.firstName} &nbsp; {this.props.data.user.lastName}</p>
 <br></br>
 <p style={{color:"#072438"}}>Gender: {this.props.data.user.gender} </p>
 <br></br>
 <p style={{color:"#072438"}}>Description:
 {
    this.props.data.user.description.length < 100 ? <>{`${this.props.data.user.description.substring(0, 100)}`}</>  :<>{`${this.props.data.user.description.substring(0, 100)}...`}</>
 }
 
  </p>
 


 <br></br>
 <p style={{color:"#072438"}}><span style={{fontWeight:"bold"}}>{this.state.messages} </span>matches &nbsp;&nbsp;
 </p>

</div>
</div>
<br></br>
<hr style={{width:"100%",backgroundColor:"grey"}} ></hr>
<br></br>
<br></br>
<div  className="allphotos">
 {rows}
</div>
<div>

</div>


</div>
</div>



    </div>

}
</>
    )

  }
};

export default connect (mapStateToProps)  (Profile);

