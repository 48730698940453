import React, { Component } from 'react';
import './Waiting.css';
import Phones from '../Assets/twophones.png';
import Back from '../Assets/Backgroundwaiting.svg';
import Apple from '../Assets/Apple.png';
import Google from '../Assets/GooglePlay.png';


class Waiting extends Component {
    render() {
        return (
            <>
                <div id="waitingdiv" style={{ display: "grid" }}>


                    <div className="back-imagewaiting">
                        <img src={Back} alt="" className="imageresponsive11" />
                        <div className="blur-filter"></div>
                    </div>

                    <div className="divvvwaiting">
                        <div className="waitcontent" data-aos="fade-right" data-aos-once="true">
                            <span id="titlewait">WHAT ARE YOU WAITING FOR?</span>
                            <br></br>
                            <br></br>
                            <p style={{ color: "#072438" }}>Still here? See why athletes love our app, </p>

                            <p style={{ color: "#072438" }}>and why so many are using it!  </p>

                            <p style={{ color: "#072438" }}>Download it now!</p>
                            <br></br>

                            <div id="divbuttons" style={{ position: "relative", zIndex: "1" }}>
                                <button className="waitingbuttons" onClick={(e) => window.open('https://apps.apple.com/lb/app/speer/id1601303982', '_blank')}>
                                    <img style={{ width: "18%" }} src={Apple} ></img>
                                    &nbsp;&nbsp;<span>App Store</span></button>
                                <button className="waitingbuttons marginn" onClick={(e) => window.open('https://play.google.com/store/apps/details?id=com.athletesapp', '_blank')} ><img style={{ width: "18%" }} src={Google} ></img>&nbsp;&nbsp;
                                    <span>Google Play</span></button>
                            </div>


                        </div>
                        <img className="logoimage" data-aos="fade-left" data-aos-once="true" src={Phones}></img>






                    </div>

                </div>
            </>


        )
    }


}

export default Waiting;