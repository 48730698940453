import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import './Newsletter.css';

import Imageuserr from '../Assets/goal1.png';

const Imageuser = (props) => {
const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
	setOpen(true);
};

const handleClose = () => {
	setOpen(false);
};

return (
	<div>
	<a style={{cursor:"pointer"}} 
			 onClick={handleClickOpen}>
		<img src={props.imagename} className="photo"></img>
	</a>
	<Dialog open={open} onClose={handleClose} >
		
		<DialogContent>
        <img src={props.imagename}  style={{width:"100%",height:"80vh"}} ></img>
 
 
		</DialogContent>
		<DialogActions>
		
		</DialogActions>
	</Dialog>
	</div>
);
}

export default Imageuser;
