import './App.css';
import { useEffect, useState } from 'react'


import * as api from "../src/api/index";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import Home from "./pages/Homepage";

import UserDashboard from "./pages/UserDashboard";


//import Profile from "./pages/UserDashboard";
import React from "react";
import Home from './pages/Homepage';
import Profile  from "./pages/Profile";
import Privacy from "./pages/Privacypolicy";


function App() {

  return (
 
    <Router>
     <Switch>
      <Route exact path="/" component={Home} />
     
   
	  
	    <Route path='/Dashboard' exact component={UserDashboard} />
          <Route path='/Profile' component={Profile} />
          <Route path='/Privacy' component={Privacy} />
         
		  
    </Switch>
   </Router>
  );
}
export default App;
