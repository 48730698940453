import React , {Component}  from 'react';
import { BrowserRouter as Router, Switch, Route ,Link} from "react-router-dom";
import SigninDialog2 from '../Dialogs/SigninDialog';
import {ReactComponent as Facebook } from '../Assets/Facebook.svg';
import {ReactComponent as Instagram } from '../Assets/Instagram.svg';

import Joinusd from '../Dialogs/JoinusDialog';
import Logo from '../Assets/logo-white.svg';
import Btlogo from '../Assets/logo-dark.svg';

import './Footer.css';


class Footer extends Component{
       render (){
           return (
<>
<div id="footerdiv">
    <div className="insidediv">
<div className="divleft" >
  <Link  to="/"><img  src={Logo} style={{width:"5em"}}></img></Link>

<Joinusd name="JOIN US" idname="clickme"  style={{cursor:"pointer"}}  black={false}></Joinusd>

<SigninDialog2 name="SIGN IN" idname="clickme"   style={{cursor:"pointer"}}    black={false} ></SigninDialog2>

{/*<Link  className="buttonshowcontact"  target="_blank" to="contactus" >CONTACT US</Link>   */}


</div>
<div className="divright" style={{position:"relative",zIndex:"0"}}>
<a className="hovereffect" target="_blank" href="https://www.facebook.com/Speerapp-101955782315484/"><div style={{width:"30px",height:"28px",borderRadius:"50%"}} >
 <Facebook></Facebook>
  </div></a>

   <a className="hovereffect" style={{marginLeft:"2.5%"}} target="_blank" href="https://instagram.com/speer.app?utm_medium=copy_link"> <div style={{width:"30px",height:"28px",borderRadius:"50%"}} >
  <Instagram></Instagram>
   </div></a>

  


</div>
    </div>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

   <hr style={{backgroundColor:"grey",margin:"0 auto"}}></hr>
   <div className="rights1">
  <p className="design2">
 © 2021 SpeerApp Website, Inc. All rights reserved.
  </p>
  <p  className="design1">
<Link style={{textDecoration:"none"}}    target="_blank" to="Privacy" ><span className="buttonshowcontact"  style={{cursor:"pointer",textDecoration:"none"}}  >Privacy Policy</span></Link>
</p>

  </div>
  <br></br>
 
  <p style={{textAlign:"center",color:"white"}}>Designed & Developed By &nbsp;&nbsp;<a target="_blank" href="http://btekup.com/" style={{cursor:"pointer",color:"#ad1f23",textDecoration:"none",verticalAlign:"middle"}}> <img  style={{width:"4em",verticalAlign:"middle"}} src={Btlogo}></img></a></p>
  <br></br>
  

</div>
</>


           )
       }


}

export default Footer;