import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer, userReducer, allUsersReducer } from './reducers/userReducers';

const reducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    allUsers:allUsersReducer,
    
})
const middleware = [thunk];

const persistConfig = {
    key: "root",
    storage,
  };
  
  const persistedReducer = persistReducer(persistConfig, reducer);
  
  const store = createStore(persistedReducer,composeWithDevTools(applyMiddleware(...middleware)));
 export let persistor = persistStore(store);


export default store;
