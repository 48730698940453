import React , {Component}  from 'react';
import './Goals.css';

import Goal1 from '../Assets/goal1.png';
import Goal2 from '../Assets/goal2.jpg';
import Goal3 from '../Assets/goal3.jpg';



class Goals extends Component{
    state = {
        class1 :"goalsimages",
        class2 : "picdiv",
        class3 :"goalsimages",
        class4 : "picdiv",
        class5 :"goalsimages",
        class6 : "picdiv"
        
       
     
      }
       render (){
           return (
<>
<div id="goalsdiv" >
    

        <div className="divvvgoal">
        <br></br>
<br></br>
           <p className="titlegoal"> GOALS AND CHALLENGES </p>

<div className="Allgoals">
<div className="goals" data-aos="fade-down" data-aos-once="true"  onMouseOver={() => {
            this.setState({
              class1: "goalsimagesdisplay", class2: "picdivdisplay"


            } )
          }}    onMouseLeave={() => {
            this.setState({
              class1: "goalsimages", class2: "picdiv"


            } )
          }}>
<div className={this.state.class1} style={{backgroundColor:"white",height:"30%",
width:"98%",opacity:"75%",position:"absolute",bottom:"0",}} >
<p className={this.state.class2}><span className="titlepic">Enjoy the competition</span>
<br></br>
<span className="contentpic">Who wants to play sports alone?Download and use speer app to find out nearby Matches happening right now.</span>
</p>
</div>
<img className="goalsimages1"  src={Goal1} alt="" />


</div>
<div className="goals" data-aos="fade-up" data-aos-once="true"   onMouseOver={() => {
            this.setState({
              class3: "goalsimagesdisplay", class4: "picdivdisplay"


            } )
          }}    onMouseLeave={() => {
            this.setState({
              class3: "goalsimages", class4: "picdiv"


            } )
          }}>
<div className={this.state.class3} style={{backgroundColor:"white",height:"30%",
width:"98%",opacity:"75%",position:"absolute",bottom:"0"}}>
<p id="trtr" className={this.state.class4}><span className="titlepic">Health and Fitness</span>
<br></br>
<span className="contentpic">Get motivated to live a healthier life! download and use speer app to find your next workout partner.<br></br></span>
</p>
</div>
<img className="goalsimages1"  src={Goal2} alt="" />

</div>
<div className="goals" data-aos="fade-down" data-aos-once="true"  onMouseOver={() => {
            this.setState({
              class5: "goalsimagesdisplay", class6: "picdivdisplay"


            } )
          }}    onMouseLeave={() => {
            this.setState({
              class5: "goalsimages", class6: "picdiv"


            } )
          }}>
<div className={this.state.class5} style={{backgroundColor:"white",height:"30%",
width:"98%",opacity:"75%",position:"absolute",bottom:"0"}}>
<p className={this.state.class6}><span className="titlepic">unlock your potential!</span>
<br></br>
<span className="contentpic">Raise your limits, and beat the goals set by other athletes.download speer app now to reach your highest potential and capability.</span>
</p>
</div>
<img className="goalsimages1"  src={Goal3} alt="" />


</div>

</div>
<br></br>
            <br></br>

            </div>
            

         
</div>

</>


           )
       }


}

export default Goals;