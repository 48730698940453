import React , {Component}  from 'react';

import Navbar from '../components/NavBar';
import Start from '../components/Getstartedsection/Getstarted';
import Footer from '../components/FooterSection/Footer';
import Feature from '../components/Appfeature/Appfeature';
import Goals from '../components/Goals/Goals';
import Waiting from '../components/Waiting/Waiting';
import  { AiOutlineUpCircle } from  "react-icons/ai";
import Arrowup from '../components/Assets/arrowup.png';

import {HiArrowCircleUp} from "react-icons/hi";


import {
  HideBetween,
  HideDuring,
  HideOn,
  HideScroll,
} from "react-hide-on-scroll";


import Car from '../components/Car/Car';
import { SliderData } from '../components/Car/SliderData';

class Homepage extends Component {
    state = {
        visible :true
        
     
      }
    render() {
        return (
            <section >
              <Navbar black={this.state.visible}></Navbar>
            <Start></Start>
            <Feature></Feature>
            <Waiting></Waiting>
            <Goals></Goals>
          
            <Car slides={SliderData}></Car>
              <Footer></Footer>
            

<HideBetween div inverse startDivID="AppFeature" endDivID="carouselsection">
    <a href="#GetStarted">          
    <img src={Arrowup} id="hiarrow" style={{ transform: "rotate(-90deg)",width:"2.5em",position:"fixed",bottom:"5px",right:"5px"}}></img>

</a>  
      </HideBetween>
            
            </section>
          );
        }
      }
      
export default Homepage;
      