import React ,{Component} from 'react';

import Navbar from '../components/Navbarside/Navbar';

import Dashboard from '../components/Dashboard/Dashboard';
class UserDashboard extends Component {
    render() {
        return (
            <>
             <Navbar color1="grey" color2="white" color3="white" />
           
            
           </>
          );
        }
      }
      
export default UserDashboard;
      