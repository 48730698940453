import React , {Component}  from 'react';

import Navbar from '../components/NavBar';
import Privacyy from '../components/Privacy/Privacy';
import Footer from '../components/FooterSection/Footer';
class Homepage extends Component {
    state = {
        visible :true
        
     
      }
    render() {
        return (
            <section >
              <Navbar black={this.state.visible}></Navbar>
           <Privacyy></Privacyy>
           <Footer></Footer>
            
            </section>
          );
        }
      }
      
export default Homepage;
      