export const SliderData = [
  {
    image:
     './images/joelle.jpg'
    ,
    name:<><span style={{color:"#ad1f23"}}> Joelle S. 28. </span> </>,
  review:<> <span style={{color:"#072438"}}> “Speer saved my life! I’m finally on the right healthy track again!”</span>  </>
    },
  {
    image:
    './images/anthony.jpg'
    ,
    name:<><span style={{color:"#ad1f23"}}>Anthony, T. 38. </span></>,
  review:<> <span style={{color:"#072438"}}>“I found my life partner, I just matched with her, we both love running, Speer helped us connect!”</span></>
    },
  {
    image:
    './images/cynthia.jpg'
    ,
    name:<><span style={{color:"#ad1f23"}}>Cynthia, B. 22.</span></>,
  review:<> <span style={{color:"#072438"}}> “Speer helped me unlock my potentials, it kept me motivated to reach my goal, I love this app!”</span></>
    },
  {
    image:
    './images/ali.jpg'
    ,
    name:<><span style={{color:"#ad1f23"}}>Ali, A. 45. </span></>,
  review:<> <span style={{color:"#072438"}}>“I can’t believe athletes community still welcome my age, I played tennis with the most wonderful people! I appreciate so much the services provided.”</span></>
    },
  
];