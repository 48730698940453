import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.scss";
import SigninDialog from "./Dialogs/SigninDialog";
import JoinusDialog from "./Dialogs/JoinusDialog";
import  { useRef } from "react";
import { Button } from "react-bootstrap";
import {connect} from 'react-redux';
import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
import Logo from './Assets/logo-white.svg';
import {useDispatch} from "react-redux";
import {logout} from "../actions/userActions"
import {Link } from 'react-router-dom';
import { color, style } from "@mui/material/node_modules/@mui/system";

function NavBar(props) {
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();
  const logoutHandler = async () => {

    await dispatch(logout());


  };
  const handleClick = () => setClick(!click);


  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  
const toggleClass = () => {
  setisMenu(isMenu === false ? true : false);
  setResponsiveclose(isResponsiveclose === false ? true : false);
};

let boxClass = ["main-menu menu-right menuq1"];
if(isMenu) {
    boxClass.push('menuq2');
}else{
    boxClass.push('');
}

const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  
const toggleSubmenu = () => {
  setMenuSubMenu(isMenuSubMenu === false ? true : false);
};

let boxClassSubMenu = ["sub__menus"];
if(isMenuSubMenu) {
    boxClassSubMenu.push('sub__menus__Active');
}else {
    boxClassSubMenu.push('');
}


  

  
  return (
    <>
    <div class="container">
  <div class="top">
      <nav className="navbar" style={{backgroundColor:"#072438"}}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
           <img src={Logo} style={{width:"4em",marginTop:"0%",position:"relative",zIndex:"2"}}></img>
           
          </NavLink>
         
          <ul className={click ? "nav-menu active" : "nav-menu"}>
         

          <li id="nav-item"    className={`${props.black ? "" : "white"}`}>
             <JoinusDialog idname="nav-links" name="Get the app"  black={props.black}></JoinusDialog>
            </li>


            <li id="nav-item"    className={`${props.black ? "" : "white"}`}>
              <NavLink
                exact
                to="/"
                
                activeClassName="active" 
                id="nav-links"
                className={`${props.black ? "" : "white"}`}
                onClick={handleClick} 

              >
                Home
              </NavLink>
            </li>
          {/*  <li id="nav-item"   className={`${props.black ? "" : "white"}`}>
              <NavLink
                exact
                to="/contactus"
                activeClassName="active" 
                id="nav-links"
                className={`${props.black ? "" : "white"}`}
                onClick={handleClick} 
              >
               Contact Us
              </NavLink>
            </li>
  */}

       {props.data===null ? <>
        <li id="nav-item"    className={`${props.black ? "" : "white"}`}>
             <SigninDialog  idname="nav-links" black={props.black} name="Sign in"></SigninDialog>
            </li>

         
       </>   :
        <>
        {props.data===undefined || props.data['success'] == false || props.data['isExist'] == false ?
         <>
            <li id="nav-item"   className={`${props.black ? "" : "white"}`}>
             <SigninDialog idname="nav-links" black={props.black} name="Sign in"></SigninDialog>
            </li>

          
        </>   :
         <>
       
       <li  onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link style={{color:'white'}} to="#">   { props.data.user.firstName} <FiChevronDown /> </Link>
                        <ul className={boxClassSubMenu.join(' ')} > 
                            <li className="nolist"> <NavLink onClick={toggleClass} activeClassName='is-active'  to={`/Profile`}> My Profile </NavLink> </li>
                            <li className="nolist"><NavLink onClick={logoutHandler} activeClassName='is-active' to={`/`}> Logout </NavLink> </li>
                        </ul>
                    </li>
       
       </>
       }
       
       </>}



           
        

         


           
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
      </div>
      </div>
      
    </>
  );
}

function mapStateToProps(state) {
  return {data:state.auth.authData}
}
export default connect (mapStateToProps)(NavBar);
