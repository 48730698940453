import axios from 'axios'
import * as api from '../api/index.js';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    NEW_PASSWORD_REQUEST,
    NEW_PASSWORD_SUCCESS,
    NEW_PASSWORD_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    DELETE_USER_RESET,
    UPDATE_PASSWORD_FAIL,
    CLEAR_ERRORS,
    AUTH,
    LOGOUT
} from '../constants/userConstants'
import { forPassword, resPassword, upPassword, upProfile } from "../api/index.js";

//login
export const login = (email, password) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_REQUEST});
        const {data}=await api.Login(email, password)
        dispatch({ type: AUTH, data });
        return data
    

        
    } catch (error) {
        dispatch({
            type:LOGIN_FAIL,
            payload: error.response.data.message
        })
    }
}

//Register user

export const register = (userData) => async (dispatch) => {
    try {

        dispatch({ type: REGISTER_USER_REQUEST});
        const {data}=await api.Register(userData)
    
        dispatch({ type: AUTH, data });
        return data
    
    } catch (error) {
        dispatch({
            type:REGISTER_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

//Load user

export const loadUser = () => async (dispatch) => {
    try {
        dispatch({ type: LOAD_USER_REQUEST })


        const { data } = await axios.get('/api/v1/me')

        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data.user
        })

    } catch (error) {
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

//Update profile

export const updateProfile = (userData, user) => async (dispatch) => {
    try {
        dispatch({type:UPDATE_PROFILE_REQUEST})
    
        const {data}=await api.upProfile(userData, user)
    
        dispatch({
            type: AUTH, data
        })
    
    return data
    } catch (error) {
        dispatch({
            type:UPDATE_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}

//Update password

export const updatePassword = (passwords, user) => async (dispatch) => {
    try {


        const {data}=await api.upPassword(passwords, user)
    
        dispatch({ type: AUTH, data });
        console.log('daatatatatta:', passwords)
        return data
    
    
    } catch (error) {
        console.log(error)
    }
}


//Forgot password

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({type:FORGOT_PASSWORD_REQUEST})
    
    
        const {data}=await api.forPassword(email)
    
        dispatch({
            type:FORGOT_PASSWORD_SUCCESS,
            payload: data.message
        })
    
        console.log('dagdgydg:', data)
    
        return data
    
    
    } catch (error) {
        dispatch({
            type:FORGOT_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }
}

//Reset password

export const resetPassword = (token, passwords) => async (dispatch) => {
    try {
        dispatch({type:NEW_PASSWORD_REQUEST})
    
        const {data}=await api.resPassword(token, passwords)
    
        dispatch({
            type:NEW_PASSWORD_SUCCESS,
            payload: data.success
        })
    
    
    } catch (error) {
        dispatch({
            type:NEW_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }
}


//Logout user

export const logout = () => async (dispatch) => {
    try {
        dispatch({
            type:LOGOUT,
        })
    
    
    } catch (error) {
        dispatch({
            type:LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}

//Get all user

export const allUsers = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_USERS_REQUEST })


        const { data } = await api.allUsers()

        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users
        })


    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete user - ADMIN
export const deleteUser = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_USER_REQUEST })

        const { data } = await api.deleteUser(id)

        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}


//clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}

export const getUserMatchings = async(userId)=>{
    const config={
        headers:{
            'Content-Type':'application/json',
            // 'Authorization': 'Bearer ' + tokens.accessToken
        }
    }

console.log('userid',userId)
    const {data} = await axios.post(`https://backend.speerapp.com/api/v1/getUserMatchings/${userId}`,config)
    console.log('data:', data)
    // console.log("Suggest:",data.suggestions)
    return data

}