import axios from 'axios'


import React, { Component} from "react";
import './Dashboard.css';
import {connect} from 'react-redux';
import { getUserMatchings } from '../../actions/userActions';
function mapStateToProps(state) {
  return {data:state.auth.authData}
}

class Dashboard extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      messages:0,
      count:0
    };
  }
  render() {
    
    const match = async () => {
      if(this.state.count==0){
        this.state.count++;
    const matchings = await getUserMatchings(this.props.data.user._id);
    if (matchings) {
      this.setState({messages:matchings.matchings.length});
    };
  };
  };
match();
    return (
<>

{this.props.data===undefined || this.props.data['success'] == false || this.props.data['isExist'] == false ? 
<p>You are not Logged in ! </p> :
<>
<div className='dashboardiv'>
   
      <h1>Dashboard</h1>
      <hr className="hr"></hr>

      <div className="containercomingsoon">

<div className="comingsoon">
<p style={{padding:"5px 5px 5px 5px"}}>Progress Statisitcs</p>
<h2 style={{padding:"5px 5px 5px 5px"}}>Coming soon</h2>
</div>
<div className="comingsoon">
<p style={{padding:"5px 5px 5px 5px"}}>Upcoming Events</p>
<h2 style={{padding:"5px 5px 5px 5px"}}>Coming soon</h2>
</div>

      </div>

  <div className="matches">
    Matches &nbsp; &nbsp; {this.state.messages}
      
      
      </div>    
      




    </div>
</>}


</>
    )

  }
};

export default connect (mapStateToProps) (Dashboard);

