import React , {Component}  from 'react';
import './Appfeature.css';
import image from '../Assets/Background.svg';
import Bubble1 from '../Assets/BubbleCreate.svg';
import Bubble2 from '../Assets/BubbleMatch.svg';
import Phone from '../Assets/iPhoneFrontAlone.png';
import Bubble3 from '../Assets/BubbleChat.svg';
import Bubble4 from '../Assets/Find.svg';


class Appfeature extends Component{
       render (){
           return (

<section id="AppFeature" style={{display:"grid"}}>
    
<div className="back-image1">
          <img src={image} alt="" className="imageresponsive" />
        
        </div>
       
        <div className="divvv1">
           <p className="titleapp"> App Features </p>

<div className="Allfeatures" style={{overflow:"hidden"}}>
<div className="features" >
<img className="bubbles1" data-aos-delay="1000" data-aos="zoom-in-left" data-aos-once="true" src={Bubble1} alt="" />

<img className="bubbles2" data-aos-delay="1000"  data-aos="zoom-in-left" data-aos-once="true" src={Bubble2} alt="" />
</div>
<div  className="features" data-aos="zoom-in" data-aos-once="true"
     data-aos-anchor-placement="center-bottom">
<img className="phone" src={Phone} alt="" />
</div>
<div className="features" >
<img className="bubbles1" data-aos-delay="1000" data-aos="zoom-in-right" data-aos-once="true" src={Bubble3} alt="" />
<img className="bubbles4" data-aos-delay="1000" data-aos="zoom-in-right" data-aos-once="true" src={Bubble4} alt="" />
</div>

</div>


            </div>
          

</section>



           )
       }


}

export default Appfeature;