import React, { useState } from 'react';
import { SliderData } from './SliderData';

import './Car.scss';
const Car = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [color1, setColor1] = useState("black");
  const [color2, setColor2] = useState("grey");
  const [color3, setColor3] = useState("grey");
  const [color4, setColor4] = useState("grey");
  const [color5, setColor5] = useState("grey");
  const timeoutRef = React.useRef(null);
  const length = slides.length;
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrent((current) =>
       current === length - 1 ? 0 : current + 1 ),
       6000
     );
       if(current==0){
        setColor2("grey");setColor1("black");setColor3("grey");setColor4("grey"); setColor5("grey")
      }
      if(current==1){
        setColor1("grey");setColor2("black");setColor3("grey");setColor4("grey");setColor5("grey")
      };
      if(current==2){
        setColor2("grey");setColor3("black");setColor1("grey"); setColor4("grey");setColor5("grey") 
      };
      if(current==3){
        setColor2("grey");setColor4("black");setColor3("grey");setColor1("grey");setColor5("grey")
      };
    return () => {
      resetTimeout();
    };
  }, [current]);

  

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };


  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  

console.log(current);
  return (
    <>
    
    <section id="carouselsection" >
 
<br></br>
<br></br>
<h1 id="reviewtitle" >Reviews</h1>

{SliderData.map((slide, index) => {
        return (
   
         
<>
       
<div style={{width:"85vw",margin:"0 auto"}}    className={index === current ? 'svgwrapperrr' : 'svgwrapperrrdiv'}  
            key={index}  >

<svg style={{marginTop:"7%"}}  id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1640 921.11">
  
<defs>
      <pattern id={slide.image} x="0" y="0" width="1" height="1">
        <image style={{height:"106%"}}  
               href={slide.image}/>
      </pattern>
  </defs>
  <defs>

</defs><title>reviewimage</title>
<path  className={index === current ?
index%2 === 0 ?   "activereview2 cls-1" : "activereview cls-1" : index%2 === 0 ? "notactivereview2 cls-1" : "notactivereview cls-1"

 }

 d="M950.22,0h689.71V862.62H1053.05A102.83,102.83,0,0,1,950.22,759.79Z"/>
<path 
 className={index === current ?
  index%2 === 0 ?   "activereview3 cls-2" : "activereview cls-2" : index%2 === 0 ? "notactivereview3 cls-2" : "notactivereview cls-2"
  
   }

 fill={`url(#${slide.image})`}
 d="M1554.49,921.11H1013.16c-61,0-110.46-48.94-110.46-109.31V85.52h554.62c53.66,0,97.17,44.6,97.17,99.61Z"
 >
    
  
 </path>
 
<path
className={index === current ?
  index%2 === 0 ?   "activereview4 cls-1" : "activereview cls-1" : index%2 === 0 ? "notactivereview4 cls-1" : "notactivereview cls-1"
  
   }
 d="M922.87,517.72H882.52V696.84A20.18,20.18,0,0,0,902.7,717h0a20.17,20.17,0,0,0,20.17-20.17Z"/>


<rect  className={index === current ?
      index%2 === 0 ?   "activediv1 cls-1" : "activediv cls-1" : index%2 === 0 ? "notactivediv1 cls-1" : "notactivediv cls-1"
      
       }    y="150.63" width="386.23" height="46.32"/><rect   className={index === current ?
        index%2 === 0 ?   "activediv1 cls-1" : "activediv cls-1" : index%2 === 0 ? "notactivediv1 cls-1" : "notactivediv cls-1"
        
         } y="120.49" width="386.23" height="14.55"/>

  
    
 
<rect class="cls-3" y="1.48" width="1639.93" height="919.63"/>

</svg>
</div>

     <div id="topdiv" style={{width:"40%",position:"absolute"}}  className={index === current ?
 index%2 === 0 ?   "activediv6 fontclasss" : "activedivv6 fontclasss" : index%2 === 0 ? "notactivediv6 fontclasss" : "notactivedivv6 fontclasss"
 
  } >
    {slide.name}
<br></br>
    {slide.review} 
    </div>
  
</>
          
        );
      })}
     

      
    <ol class="carousel-indicatorsss" >
    <li style={{backgroundColor:color1}}  onClick={() => [setCurrent(0), setColor2("grey"), setColor1("black"), setColor3("grey"), setColor4("grey"), setColor5("grey") ] }></li>
    <li style={{backgroundColor:color2}}  class="" onClick={() => [setCurrent(1) , setColor1("grey"), setColor2("black"), setColor3("grey"), setColor4("grey"), setColor5("grey") ]}></li>
    <li style={{backgroundColor:color3}}  class="" onClick={() => [setCurrent(2), setColor2("grey"), setColor3("black"), setColor1("grey"), setColor4("grey"), setColor5("grey") ]}></li>
    <li style={{backgroundColor:color4}}  class="" onClick={() => [setCurrent(3), setColor2("grey"), setColor4("black"), setColor3("grey"), setColor1("grey"), setColor5("grey") ]}></li>
   </ol>
    </section>
    </>
  );
};

export default Car;