import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import './Dialog.css';
import Image from '../Assets/twophones.png';
import Signd from './SigninDialog';
import Apple from '../Assets/Apple.png';
import Google from '../Assets/GooglePlay.png';
import { AiOutlineClose } from "react-icons/ai";


const JoinusDialog = (props) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<a style={{ cursor: "pointer" }} id={props.idname} className={`${props.black ? "" : "white"}`}
				onClick={handleClickOpen}>
				{props.name}
			</a>
			<Dialog open={open} onClose={handleClose} id="ttttt">
				<AiOutlineClose style={{ cursor: "pointer" }} id="closebtn" onClick={handleClose}></AiOutlineClose>
				<DialogContent>
					<div id="joinusdiv">
						<div className="alljoincontent">
							<div style={{ paddingTop: "5%", paddingLeft: "3%", cursor: "pointer" }}>
								<p id="p1" style={{ color: "#ad1f23" }}>Join Us</p>
								<p style={{ color: "#072438" }}>New here?Please download the app in order to create an account and get started.
									You will also be able to sign in here after you created an account in order to track your
									progress!
									Already a member ?<Signd name="Sign in" idname="clickmee" style={{ cursor: "pointer", textDecoration: "underline" }} black={false} ></Signd>
								</p>
								<br></br>
								<div className="alljoinbuttons">
									<button id="waitingbuttonsjoin" onClick={(e) => window.open('https://apps.apple.com/lb/app/speer/id1601303982', '_blank')}>
										<img style={{ width: "18%" }} src={Apple}></img>
										&nbsp;&nbsp;<span>App Store</span></button>
									<button id="waitingbuttonsjoin" onClick={(e) => window.open('https://play.google.com/store/apps/details?id=com.athletesapp', '_blank')} className="marginnjoin"><img style={{ width: "18%" }} src={Google} ></img>&nbsp;&nbsp;
										<span>Google Play</span></button>            </div>
							</div>
							<img src={Image} className="imagephone"></img>
						</div>
					</div>

				</DialogContent>
				<DialogActions>

				</DialogActions>
			</Dialog>
		</div>
	);
}

export default JoinusDialog;
