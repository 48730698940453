import React from 'react';

import Navbar from '../components/Navbarside/Navbar';
import Profilee from '../components/Profileuser/Profile';
function Profile() {
  return (
    <>
    <Navbar color2="grey" color1="white" color3="white"/>
  <Profilee></Profilee>
    </>
  );
}

export default Profile;
