import axios from 'axios';
const API = axios.create({baseURL: 'https://backend.speerapp.com'});

API.interceptors.request.use((req) => {
  if (localStorage.getItem('User')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('User')).token}`;
  }

  return req;
});

export const Login= (email,password) => API.post('/api/v1/login',{email,password})
export const Register= (userData)=>API.post('/api/v1/register', userData) //THIS ONE IS WORKING BAS MA3AM TKEBNE LOGIN MAZBOUTA
// export const loUser= (user) =>API.get(`/api/v1/me/${user._id}`)
export const upProfile= (userData, user)=>API.put(`/api/v1/me/update/${user._id}`, userData)
export const upPassword= (passwords, user)=>API.put(`/api/v1/password/update/${user._id}`, passwords)
export const forPassword= (email)=>API.post('/api/v1/password/forgot', email)
export const resPassword= (token,passwords)=>API.put(`/api/v1/password/reset/${token}`, passwords)
export const Logout= ()=>API.get('/api/v1/logout')
export const allUsers= ()=>API.get('/api/v1/admin/users')
export const deleteUser= (id)=>API.delete(`/api/v1/admin/user/${id}`)
export const UserMatchings=(id)=>API.get(`/api/v1/getUserMatchings/${id}`)