
import React , {Component}  from 'react';

const Slide = (props) => {
    const btnContainer = {
      display: "inline-block",
      float: "right"
    };
    const btn = {
      border: "none",
      background: "blue",
      color: "white",
      fontSize: "22px",
      padding: "5px 10px",
      borderRadius: "10px",
      margin: "0 10px"
    };
    return (
      <React.Fragment>
        <img src={props.image.link} id="imagecarid" alt="Sliderr_image" />
     
      </React.Fragment>
    );
  };
  const Slider = (props) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const slides = [
      {
        id: 1,
        title: "First Slide",
        link: "./images/PhonePic1.png"
      },
      {
        id: 2,
        title: "Second Slide",
        link: "./images/PhonePic2.png"
      },
      {
        id: 3,
        title: "Third Slide",
        link: "./images/PhonePic3.png"
      },
     
    ];
    const slideNext = (e) => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : currentSlide + 1;
      });
    };
    const slidePrev = (e) => {
      setCurrentSlide((prev) => {
        return prev === 0 ? slides.length - 1 : currentSlide - 1;
      });
    };
    React.useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentSlide((prev) => {
          return prev + 1 === slides.length ? 0 : prev + 1;
        });
      }, 4000);
      return () => {
        clearInterval(intervalId);
      };
    }, []);
    return (
      <React.Fragment>
       
        <Slide 
          image={slides[currentSlide]}
          slideNext={slideNext}
          slidePrev={slidePrev}
        />
      </React.Fragment>
    );
  };
  export default Slider;
  