import React from 'react';
import { useAlert } from 'react-alert'
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/userActions'
import  { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useHistory } from "react-router-dom";

  let aler=false;
let open = false;
let success= false;
const SigninDialog = (props) => {
	const alert = useAlert()
const [open, setOpen] = React.useState(false);
const [aler, setAler] = React.useState(false);

const [messageloading, setMessageloading] = useState("Sign in");
const [values, setValues] = React.useState({
	password: "",
	showPassword: false,
});

const handleClickShowPassword = () => {
	setValues({ ...values, showPassword: !values.showPassword });
};

const handleMouseDownPassword = (event) => {
	event.preventDefault();
};

const handlePasswordChange = (prop) => (event) => {
	setValues({ ...values, [prop]: event.target.value });
};

const handleClickOpen = () => {
	setOpen(true);
};

const handleClose = () => {
	setOpen(false);
};
const alerClose = () => {
	setAler(false);
};
const alerOpen = () => {
	setAler(true);
};
const history = useHistory();

const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const dispatch = useDispatch();
const { isAuthenticated, loading } = useSelector(state => state.auth);
console.log('isAuthenticated:', isAuthenticated)
const submitHandler = async (e) => {
    
setMessageloading("Loading...");
    e.preventDefault()

    console.log('loading:', loading)

    const data = await dispatch(login(email, password))
    //
    if (data) {
		console.log('dataLog:', data);
    }
	if (data.success==true) {
		console.log('dataLog:', data);
		setOpen(false);
		alert.show('Signed In !');
		setMessageloading("Sign in");
		history.push("/Profile");
				
    }
	if (data.success==false || data.isExist==false) {
		console.log('sada:', aler);
		setAler(true);
		console.log('sada:', aler);
		setMessageloading("Sign in");
    }
    if (data ===undefined) {
      success=false;
	  setMessageloading("Sign in");
    }
  }

return (
	<div>
	<a style={{cursor:"pointer"}}  id={props.idname}  className={`${props.black ? "" : "white"}`}
			 onClick={handleClickOpen}>
	{props.name}
	</a>
	<Dialog open={open} onClose={handleClose}>
	<AiOutlineClose onClick={handleClose} style={{marginTop:"3%",marginLeft:"90%",cursor:"pointer"}}></AiOutlineClose>
		<DialogContent>
        <div  >
     <p id="p1" style={{color:"#ad1f23"}}>Sign in</p>
<p id="p2" style={{color:"#072438"}}>Already a member?Sign in so you can track your progress!</p>

<br></br>
<form onSubmit={submitHandler}>
<Input
              type="email"
              id="email_field"
			  required
              className="form-control"
              style={{fontWeight:"700", marginBottom: "10px",width:"100%",backgroundColor:"#E9E9E9",color:"#072438" }} value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email" />
			  <br></br>
<Input required

type={values.showPassword ? "text" : "password"}
              id="password_field"
			  style={{width:"100%",backgroundColor:"#E9E9E9",color:"#072438",fontWeight:"700"}}
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
			 
              placeholder="Password" 
			  endAdornment={
				<InputAdornment position="end">
				  <IconButton
					onClick={handleClickShowPassword}
					onMouseDown={handleMouseDownPassword}
				  >
					{values.showPassword ? <Visibility /> : <VisibilityOff />}
				  </IconButton>
				</InputAdornment>}
			  />
<br></br>
      
<button id="signinbutton" style={{cursor:"pointer"}} type="submit">{messageloading}</button>
<Snackbar open={aler} autoHideDuration={2000} onClose={alerClose}  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
		<Alert  severity="error" >
		Invalid Email or Password
		</Alert>
	</Snackbar>  
</form>


 </div>
 
		
		</DialogContent>
		<DialogActions>
		
		</DialogActions>
	</Dialog>
	</div>
);
}

export default SigninDialog;
